import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { planPropTypes } from 'common/propTypes';
import { DOMAINS } from 'common/constants/index';
import { initiateUserAuth } from 'common/auth/modalLogin';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import ModalComponent from 'common/components/ModalComponent';
import { paymentInitialize } from 'common/razorpay/paymentInitialize';
import classNames from 'classnames';
import PlanPurchaseTypeSelectionModal from './PlanPurchaseTypeSelectionModal';
import PaymentCoupons from './PaymentCoupons';

const propTypes = {
  plan: planPropTypes.isRequired,
  activePlanId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  couponConfig: PropTypes.shape({ code: PropTypes.string }),
  activePlanName: PropTypes.string,
  isSubscriptionActive: PropTypes.bool.isRequired,
  isCreditApplied: PropTypes.bool,
  couponCode: PropTypes.string.isRequired,
  onChangeCouponCode: PropTypes.func.isRequired,
  isApplied: PropTypes.bool.isRequired,
  onCouponUpdate: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  btnClassName: PropTypes.string,
  isHideIcon: PropTypes.bool
};
const defaultProps = {
  activePlanId: null,
  couponConfig: {},
  activePlanName: '',
  isCreditApplied: false,
  btnClassName: '',
  isHideIcon: false
};

const PlanButton = ({
  plan, activePlanId, couponConfig, activePlanName, isSubscriptionActive, isCreditApplied,
  couponCode, onChangeCouponCode, isApplied, onCouponUpdate, message, btnClassName, isHideIcon
}) => {
  const {
    id, currentNetPrice, price, plan_name: planName, is_premium: isPremium
  } = plan;
  const { hasPaymentEnabled, currentDomain } = window;
  const coupenCode = _.get(couponConfig, 'code', '');
  const isCouponAvailable = !_.isEmpty(couponConfig);
  const [isUserSignedIn, setIsUserSignedIn] = useState(window.isUserSignedIn);
  const [modalConfigs, setModalConfigs] = useState({
    isShowPurchaseTypeModal: false, isShowConfirmationModal: false
  });
  const [purchaseType, setPurchaseType] = useState('subscription');
  const paramCouponCode = currentNetPrice === price ? '' : coupenCode;
  const isEmailUser = _.get(window, 'isEmailUser', false);

  useEffect(() => {
    if (isCouponAvailable && purchaseType !== 'payment') {
      setPurchaseType('payment');
    } else {
      setPurchaseType('subscription');
    }
  }, [couponConfig]);

  const { isShowPurchaseTypeModal } = modalConfigs;

  const createPayment = () => {
    setModalConfigs({ isShowPurchaseTypeModal: false });
    const args = {
      plan_id: id,
      purchase_type: purchaseType,
      coupon_code: paramCouponCode,
      credits_applied: isCreditApplied,
      plan_name: planName
    };
    paymentInitialize(args, () => {
      setTimeout(() => { window.location.reload(); }, 1.5 * 1000);
    }, purchaseType === 'subscription');
  };

  const onSubmit = () => {
    if (isUserSignedIn && !isEmailUser) return createPayment();

    return initiateUserAuth({ action: 'plan_purchase' }).then(() => {
      createPayment();
      // window.location.reload();
      setIsUserSignedIn(true);
    }).catch(() => {
      reactToastify('Unauthorized, Please login and Continue', TOASTIFY_TYPES.ERROR);
    });
  };

  const onHandlePaymentButton = () => {
    if ((isCouponAvailable || isCreditApplied) && currentDomain !== DOMAINS.DEFAULT_DOMAIN) {
      onSubmit();
      return;
    }
    setModalConfigs({ ...modalConfigs, isShowPurchaseTypeModal: true });
  };

  const renderPurchaseTypeComponent = () => {
    return (
      <>
        <div className="mb-5 billing-type">
          <div className="d-flex align-items-center gap-10">
            <div className="alert-icons alert-info icon-payment mb-0 tx-25" />
            <h4 className="font-weight-semi">Billing Type</h4>
          </div>
          <PaymentCoupons
            couponCode={couponCode}
            onChangeCouponCode={onChangeCouponCode}
            isApplied={isApplied}
            onCouponUpdate={onCouponUpdate}
            message={message}
          />
        </div>

        <div className="d-flex flex-column gap-25">
          <PlanPurchaseTypeSelectionModal
            purchaseType={purchaseType}
            onChangePurchaseType={setPurchaseType}
          />
        </div>
      </>
    );
  };

  const isDisabled = !hasPaymentEnabled
  || (activePlanName && activePlanName !== planName) || isSubscriptionActive;

  const buttonClassName = btnClassName ? classNames(btnClassName, { disabled: isDisabled })
    : classNames({ 'premium-btn': isPremium, 'basic-btn': !isPremium, disabled: isDisabled });

  return (
    <>
      <React.Fragment key={id}>
        <form id={`planForm${id}`} action="/payment/init_payment" method="post" className="d-none">
          <input type="hidden" value={id} name="plan_id" />
          <input type="hidden" value={purchaseType} name="purchase_type" />
          <input type="hidden" value={paramCouponCode || ''} name="coupon_code" />
        </form>
        <button
          className={buttonClassName}
          type="button"
          disabled={isDisabled}
          onClick={() => { onHandlePaymentButton(plan); }}
        >
          {activePlanId ? 'Extend Now' : 'Order Now'}
          {isHideIcon ? '' : <i className="fa-solid fa-circle-arrow-right" />}
        </button>
      </React.Fragment>
      {isShowPurchaseTypeModal && (
        <ModalComponent
          onSave={() => { onSubmit(); }}
          onClose={() => { setModalConfigs({ ...modalConfigs, isShowPurchaseTypeModal: false }); }}
          title=""
          okBtnTitle="Order Now"
          btnClassName="mr-auto"
          size="lg"
        >
          {renderPurchaseTypeComponent()}
        </ModalComponent>
      )}
    </>
  );
};

PlanButton.propTypes = propTypes;
PlanButton.defaultProps = defaultProps;

export default PlanButton;
