import React from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/ModalComponent';
import MoneyDisplay from 'common/components/MoneyDisplay';
import TransactionType from 'common/components/TransactionType';
import { STATUS_STYLE_CONFIG } from 'modules/OrderDetails/config';
import moment from 'moment';
import { ORDER_TYPES } from 'common/constants/index';
import classNames from 'classnames';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import TradingSymbol from 'common/components/TradingSymbol';
import HoverMessage from 'common/components/HoverMessage';
import ForceExitConfirmation from 'common/components/ForceExitConfirmation/index';
import { onLegForceExit } from 'common/api/run';

const propTypes = {
  shouldShowModal: PropTypes.bool,
  orderType: PropTypes.string.isRequired,
  onHandleModal: PropTypes.func.isRequired,
  ordersPair: PropTypes.shape({
    jobId: PropTypes.number,
    entryOrderId: PropTypes.string,
    isManualExitShow: PropTypes.bool,
    orderProfit: PropTypes.number,
    quantity: PropTypes.number,
    tradingSymbol: PropTypes.string,
    orders: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  runStatus: PropTypes.string.isRequired
};

const defaultProps = {
  shouldShowModal: false
};

const TransactionOrderDetails = ({
  shouldShowModal, onHandleModal, ordersPair, orderType: propsOrderType, runStatus
}) => {
  const isActual = propsOrderType !== ORDER_TYPES.paper.value;
  const { currentUserId } = window;
  const {
    orderProfit, quantity, tradingSymbol, orders: actualOrders, entryOrderId, jobId, isManualExitShow
  } = ordersPair;
  const oneIndentation = ' ';
  const orderUserId = _.get(actualOrders, '0.user_id', '').toString();
  const isOrderBelongsToUser = currentUserId === orderUserId;
  const isShowLegSquareOffBtn = runStatus === 'started' && isManualExitShow && isOrderBelongsToUser;

  const onForceExit = (isReload = true, successCallback = () => { }) => {
    onLegForceExit(jobId, entryOrderId).then(() => {
      reactToastify('Close position requested succesfully', TOASTIFY_TYPES.SUCCESS);
      successCallback();
      if (isReload) setTimeout(() => window.location.reload(), 1000);
    }).catch(() => {
      reactToastify('Something went wrong, please try again', TOASTIFY_TYPES.ERROR);
    });
  };

  const orders = _.chain(actualOrders)
    .filter((order) => !(order.run_order_type === ORDER_TYPES.paper.value && order.is_stop_loss))
    .map((order) => {
      const { is_entry: isEntry, is_stop_loss: isStopLoss } = order;
      if (isEntry) return { ...order, priority: 0 };
      if (isStopLoss) return { ...order, priority: 1 };
      return { ...order, priority: 2 };
    })
    .sortBy((order) => order.priority)
    .value();

  const orderCardClassName = classNames('mb-2 mb-md-0', {
    'col-md-6': _.size(orders) === 2,
    'col-md-4': _.size(orders) !== 2
  });

  const orderPlacementDurationsKeyName = {
    place_order_api: 'Place Order',
    order_book_api: 'Retrieve OrderBook',
    cancel_order_api: 'Cancel Order',
    retry_api: 'Retry',
    place: 'Place Order API',
    cancel: 'Cancel Order API',
  };

  const renderOrder = (order, index) => {
    const {
      is_entry: isEntry, is_stop_loss: isStopLoss, transaction_type: transactionType,
      broker_order_id: brokerOrderId, status, signal_price: signalPrice, signal_time: signalTime,
      traded_price: tradedPrice, traded_time: tradedTime, placed_time: placedTime, product,
      cancelled_quantity: cancelledQuantity, order_type: orderType, sl_trailed_count: slTrailedCount,
      failure_message: failureMessage, modified_order_type: modifiedOrderType, is_waiting: isWaiting,
      is_move_to_cost: moveToCostAchieved, is_re_entry_order: isReEntryOrder,
      quantity: orderQuantity, traded_quantity: tradedQuantity,
      order_placement_durations: orderPlacementDurations, quantman_placed_time: quantmanPlacedTime
    } = order;

    const statusConfig = _.get(STATUS_STYLE_CONFIG, status);
    const statusText = _.get(statusConfig, 'statusText', status);
    const statusTextClassName = _.get(statusConfig, 'statusTextClassName', 'text-muted');
    const alteredStatusText = isWaiting && status === 'confirmed' ? 'waiting' : statusText;

    const renderType = () => {
      if (isStopLoss) return 'Stop Loss';
      if (isEntry) return 'Entry';

      return 'Exit';
    };

    const renderOrderPlacementDurations = (value, key) => {
      // Should tweak UI.
      return (
        <React.Fragment key={key}>
          <span>
            {_.get(orderPlacementDurationsKeyName, key)}
            :
            {oneIndentation}
            {_.round((value), 3)}
            {oneIndentation}
            s
          </span>
          <br />

        </React.Fragment>
      );
    };

    const renderOrderPlacementDurationHover = () => {
      if (_.isEmpty(orderPlacementDurations)) { return null; }

      return (
        <HoverMessage
          title="Order Placement Durations"
          message={_.map(orderPlacementDurations, renderOrderPlacementDurations)}
        >
          <span className="material-icons-outlined text-dark tx-12 align-middle ml-1 cursor-pointer">
            info
          </span>
        </HoverMessage>
      );
    };

    const renderSubInformation = () => {
      if (!isActual) return null;

      return (
        <div className="sub-information tx-10 gap-5">
          {brokerOrderId ? (
            <div>
              <span className="text-muted">ID : </span>
              <span className="font-weight-semi">{brokerOrderId}</span>
            </div>
          ) : null}
          {status ? (
            <span className={`${statusTextClassName} status m-0`}>
              {alteredStatusText}
            </span>
          ) : null}
        </div>
      );
    };

    const listDetails = [
      {
        isShow: signalPrice || signalTime,
        id: 'signal_time',
        leftContent: 'Quantman Signal',
        rightContent: (
          <>
            {oneIndentation}
            <span className="font-weight-medium mr-1">
              {signalPrice && <MoneyDisplay>{signalPrice}</MoneyDisplay>}
            </span>
            {oneIndentation}
            {signalTime && moment(signalTime).format('DD MMM YYYY, HH:mm:ss')}
            {oneIndentation}
          </>
        )
      },
      {
        isShow: !!quantmanPlacedTime,
        id: 'placed_time',
        leftContent: 'Quantman Placed time',
        rightContent: moment(quantmanPlacedTime).format('DD MMM YYYY, HH:mm:ss')
      },
      {
        isShow: !!placedTime,
        id: 'broker_executed_time',
        leftContent: (
          <>
            Broker Execution time
            {renderOrderPlacementDurationHover()}
          </>
        ),
        rightContent: moment(placedTime).format('DD MMM YYYY, HH:mm:ss')
      },
      {
        isShow: isActual && (tradedPrice || tradedTime),
        id: 'broker_traded_time',
        leftContent: 'Broker Traded',
        rightContent: (
          <>
            {oneIndentation}
            <span className="font-weight-medium mr-1">
              {tradedPrice && <MoneyDisplay>{tradedPrice}</MoneyDisplay>}
            </span>
            {oneIndentation}
            {tradedTime && moment(tradedTime).format('DD MMM YYYY, HH:mm:ss')}
            {oneIndentation}
          </>
        )
      },
      {
        isShow: isActual && product,
        id: 'product_type',
        leftContent: 'Product',
        rightContent: <div className="font-weight-medium">{_.upperCase(product)}</div>
      },
      {
        isShow: cancelledQuantity,
        id: 'cancelled_qty',
        leftContent: 'Cancelled Qty',
        rightContent: <div className="font-weight-medium">{cancelledQuantity}</div>
      },
      {
        isShow: isActual && orderType,
        id: 'order_type',
        leftContent: 'Order type',
        rightContent: (
          <div className="font-weight-medium text-right">
            {_.upperCase(orderType)}
            {modifiedOrderType
              && (
                <div className="tx-10 font-weight-normal">
                  Modified to
                  {oneIndentation}
                  {_.startCase(modifiedOrderType)}
                </div>
              )}
          </div>
        )
      },
      {
        isShow: slTrailedCount,
        id: 'sl_trailed_count',
        leftContent: 'Stop Loss Trailed Count',
        rightContent: <div className="font-weight-medium text-right">{slTrailedCount}</div>
      },
      {
        isShow: !!failureMessage,
        id: 'failed_message',
        leftContent: 'Failed Message',
        rightContent: <div className="font-weight-medium text-danger text-right">{failureMessage}</div>
      },
      {
        isShow: moveToCostAchieved !== null && moveToCostAchieved === true,
        id: 'movetocost',
        leftContent: 'Move to cost',
        rightContent: <div className="font-weight-medium text-danger text-right">Achieved</div>
      },
      {
        isShow: isReEntryOrder,
        id: 'reentry_order',
        leftContent: <div className="tx-12">* It is Re Entry Order</div>,
        rightContent: null
      },
      {
        isShow: orderQuantity,
        id: 'qty',
        leftContent: 'Quantity',
        rightContent: <div className="tx-12">{orderQuantity}</div>,
      },
      {
        isShow: isActual && tradedQuantity,
        id: 'traded_qty',
        leftContent: 'Traded Quantity',
        rightContent: <div className="tx-12">{tradedQuantity}</div>,
      }
    ];

    const renderListDetail = ({
      isShow, leftContent, rightContent, id
    }, idx) => {
      if (!isShow) return null;

      return (
        <div className="mt-1" key={idx} id={id}>
          {/* chekc here */}
          <div className="d-flex align-center justify-content-between py-1">
            <div className="tx-10 text-muted">{leftContent}</div>
            <div className="tx-12">{rightContent}</div>
          </div>
        </div>
      );
    };

    return (
      <div className={orderCardClassName} key={index} data-entry-type={renderType()}>
        <div className="history-inner-content h-100">
          <div className="pb-2 border-bottom">
            <div className="d-flex justify-content-between align-items-center">
              <div className="tx-12 font-weight-semi mb-1">
                {renderType()}
                {oneIndentation}
                <TransactionType type={transactionType} />
                {oneIndentation}
              </div>
            </div>
            {renderSubInformation()}
          </div>
          {_.map(listDetails, renderListDetail)}
        </div>
      </div>
    );
  };

  return (
    <ModalComponent
      onClose={() => { onHandleModal(); }}
      btnClassName="mr-auto"
      size="xl"
      shouldShow={shouldShowModal}
    >
      <div className="transaction-history">
        <div className="history-head">
          <div>
            <div className="tx-14 font-weight-medium">
              <TradingSymbol tradingSymbol={tradingSymbol} />
            </div>
            <div className="d-flex gap-10">
              <div className="sub-information gap-5">
                <div>
                  <span className="text-muted">Qty : </span>
                  <span className="font-weight-semi">{quantity}</span>
                </div>
                <span className="spacing" />
                <div className="d-flex align-items-center gap-5">
                  <span className="text-muted">P/L : </span>
                  <span className="font-weight-medium text-success d-flex align-items-center">
                    <MoneyDisplay shouldColor>{orderProfit}</MoneyDisplay>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {isShowLegSquareOffBtn
              && <ForceExitConfirmation onForceExit={onForceExit} label="Close position" />}
        </div>
        <div className="history-wrapper row mt-3">
          {_.map(orders, renderOrder)}
        </div>
      </div>
    </ModalComponent>
  );
};

TransactionOrderDetails.propTypes = propTypes;
TransactionOrderDetails.defaultProps = defaultProps;

export default TransactionOrderDetails;
