import { onCreatePayment, onCreateSubscription } from 'common/api/payment';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { initiateUserAuth } from 'common/auth/modalLogin';
import { razorpayModal } from './razorpayModal';

export const paymentInitialize = (args, immediateCallBackFunc = () => {}, isSubscription = false) => {
  const isUserSignedIn = _.get(window, 'isUserSignedIn', false);
  const isEmailUser = _.get(window, 'isEmailUser', false);

  const onCreatePurchase = () => (isSubscription ? onCreateSubscription(args) : onCreatePayment(args));

  const processPurchase = () => {
    onCreatePurchase()
      .then((result) => {
        const { status } = result;
        if (!status) {
          return reactToastify('Something went wrong, Please try again', TOASTIFY_TYPES.ERROR);
        }
        const razorpayOrderId = _.get(result, 'razorpay_order_id', '');
        const razorpaySubscriptionId = _.get(result, 'razorpay_subscription_id', '');
        const resyncTypes = _.get(result, 'resync_types', '');
        const { RAZORPAY_KEY_ID } = window;

        const razorpayModalArgs = {
          razorpayPurchaseId: razorpayOrderId || razorpaySubscriptionId,
          razorpayKeyId: RAZORPAY_KEY_ID,
          description: _.toString([resyncTypes]),
          isSubscription,
          resyncTypes,
          razorpayNotes: args,
          immediateCallBackFunc
        };

        if (razorpayOrderId === 'credit_payment') {
          reactToastify('Hurray! Purchase completed', TOASTIFY_TYPES.SUCCESS);
          setTimeout(() => immediateCallBackFunc(), 2000);
          return null;
        }

        return razorpayModal(razorpayModalArgs);
      })
      .catch(() => {
        reactToastify('Something went wrong, Please try again', TOASTIFY_TYPES.ERROR);
      });
  };

  if (!isUserSignedIn || isEmailUser) {
    initiateUserAuth({ action: 'plan_purchase', plan: args.addon_name })
      .then(() => processPurchase())
      .catch(() => {
        reactToastify('Unauthorized, Please login and Continue', TOASTIFY_TYPES.ERROR);
      });
  } else {
    processPurchase();
  }
};
