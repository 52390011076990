const I18N_SCOPE = { scope: 'folders.show' };

export const SORT_BY = {
  name: 'display_name',
  profit: 'summary.profit',
  createdAt: 'created_at'
};

export const TABLE_HEADER = [
  { title: '' },
  { title: I18n.t('name_&_details', I18N_SCOPE), sortByKey: SORT_BY.name },
  { title: 'Order Type' },
  { title: 'Instruments', },
  { title: I18n.t('created_at', I18N_SCOPE), sortByKey: SORT_BY.createdAt },
  { title: 'Status' }
];

export const VIEW_TYPE = {
  list: 'list',
  grid: 'grid'
};

export const DESKTOP_WIDTH = 1024;

export const showByOption = [
  { displayName: 'Running Strategies', value: 'running_live_runs' },
  // { displayName: 'Oneclick/Automatic', value: 'actual_live_runs' },
  // { displayName: 'Paper', value: 'paper' },
  { displayName: 'Backtest', value: 'historic' },
  { displayName: 'All', value: 'all' }
];
