import { RUN_TYPES } from 'v2/common/constants/index';

export const runListOrderTypeLabel = (run) => {
  const { run_type: runType, order_type: orderType } = run;

  const isBacktest = runType === RUN_TYPES.historic;
  const runOrderType = isBacktest ? 'Backtest' : orderType;

  return _.capitalize(runOrderType);
};
