import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { getDateDifferance } from 'common/utils/dateDifference';
import { updateRun } from 'common/api/run';
import { runPropTypes } from 'common/propTypes';
import { runListOrderTypeLabel } from 'common/utils/RunListUtil';

const MINIMIZED_SHOW_RUNS_COUNT = 8;
const EXPANDED_SHOW_RUNS_COUNT = 100;

const propTypes = {
  starredRuns: PropTypes.arrayOf(runPropTypes)
};
const defaultProps = {
  starredRuns: []
};

const I18N_SCOPE = { scope: 'pages.index' };

const onRemoveFavorite = (event, runId, starredRuns, onRunsChange) => {
  event.preventDefault();
  const newStarredRuns = _.filter(starredRuns, (run) => run.id !== runId);
  updateRun({ runId, run: { is_starred: false } })
    .then(() => onRunsChange(newStarredRuns));
};

const renderStarredRun = (starredRuns, onRunsChange) => (starredRun) => {
  const {
    name,
    end_date: endDate,
    start_date: startDate,
    run_type: runType,
    created_at: createdAt,
    id,
  } = starredRun;

  return (
    <div key={id} className="mystrategies col-sm-6 col-md-4 col-lg-3 mb-2">
      <a href={`/runs/${id}/?locale=${I18n.locale}`} className="card">
        <div className="mystrategies card-body">
          <div className="mystrategies-head d-flex justify-content-between align-items-center">
            <div className="strategies-title">{name || id}</div>
            <button
              type="button"
              className="align-self-center action-btn"
              data-toggle="tooltip"
              data-placement="top"
              title="Un select favorite"
              onClick={(event) => onRemoveFavorite(event, id, starredRuns, onRunsChange)}
            >
              <i className="material-icons-outlined tx-18">favorite</i>
            </button>
          </div>

          <div className="mystrategies-info d-flex tx-12">
            <div className="border-right mr-2 pr-2">
              <div>
                {I18n.t(`${runType}_title`, I18N_SCOPE)}
                {I18n.t('duration', I18N_SCOPE)}
              </div>
              <div className="font-weight-semi">
                {getDateDifferance(startDate, endDate)}
              </div>
            </div>

            <div>
              <div>Order Type</div>
              <div className="font-weight-semi">
                &nbsp;
                {runListOrderTypeLabel(starredRun)}
                &nbsp;
              </div>
            </div>
          </div>

          <div className="mt-1 tx-11">
            <span>
              {I18n.t('created', I18N_SCOPE)}
              &nbsp;
              {moment(createdAt).fromNow()}
            </span>
          </div>
        </div>
      </a>
    </div>
  );
};

const renderShowMore = ({ starredRuns, isExpanded, onExpandChange }) => {
  if (starredRuns.length <= MINIMIZED_SHOW_RUNS_COUNT) {
    return null;
  }
  return (
    <div className="text-center">
      <button
        type="button"
        className="btn btn-sm mt-0 btn-outline-primary"
        onClick={() => onExpandChange(!isExpanded)}
      >
        {isExpanded ? 'Show Less' : 'Show More'}
      </button>
    </div>
  );
};

const StarredRuns = ({ starredRuns }) => {
  const [runs, onRunsChange] = useState(starredRuns);
  const [isExpanded, onExpandChange] = useState(false);
  const runsCountToShow = isExpanded ? EXPANDED_SHOW_RUNS_COUNT : MINIMIZED_SHOW_RUNS_COUNT;

  if (_.isEmpty(runs)) return null;

  return (
    <div className="starred-runs-container">
      <h4 className="mb-2">{I18n.t('starred_runs', I18N_SCOPE)}</h4>
      <div className="mystrategies-container">
        <div className="mystrategies-wrapper row">
          {_.chain(runs).take(runsCountToShow).map(renderStarredRun(runs, onRunsChange)).value()}
        </div>
        {renderShowMore({ starredRuns, isExpanded, onExpandChange })}
      </div>
    </div>
  );
};

export default StarredRuns;

StarredRuns.propTypes = propTypes;
StarredRuns.defaultProps = defaultProps;
