import React, { useEffect, useState } from 'react';
import GhostContentAPI from '@tryghost/content-api';
import Pagination from 'common/components/Pagination';
import LoadingSpinner from 'common/components/LoadingSpinner';

import withErrorBoundary from 'common/components/withErrorBoundary/index';
import PostCard from './PostCard';
import PageNotFound from './PageNotFound';

const ghostsApi = new GhostContentAPI({
  host: _.get(window, 'SA_CONFIGS.ghostAPIHost', 'https://www.quantman.in/blog'),
  key: _.get(window, 'SA_CONFIGS.ghostAPIKey', ''),
  version: 'v5.0'
});

const INITIAL_PAGE_NO = 0;
const PER_PAGE_LIMIT = 15;

const Blogs = () => {
  const [blogState, setBlogState] = useState({
    posts: [],
    tags: {},
  });
  const { posts, tags } = blogState;
  const [tagName, setTagName] = useState('');
  const [pagination, setPagination] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(INITIAL_PAGE_NO);
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const results = await ghostsApi.posts.browse(
          {
            limit: PER_PAGE_LIMIT,
            page: Number(currentPageIndex + 1),
            filter: tagName ? `tag:${tagName}` : ''
          }
        );
        const tagsList = await ghostsApi.tags.browse();
        setBlogState({ posts: results, tags: tagsList });
        setPagination(results.meta.pagination);
      } catch (e) {
        console.log('Error while fetching posts', e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [currentPageIndex, tagName]);

  const renderPostCard = (post, index) => {
    const className = `post-card post ${index === 0
      ? 'post-card-large col-lg-12'
      : 'col-lg-4'} mb-25 ${post.feature_image ? '' : 'no-image'}`;

    return (
      <article className={className} key={post.id}>
        <PostCard post={post} index={index} />
      </article>
    );
  };

  const renderTagsList = (tag) => {
    return (
      <button
        type="button"
        key={tag.id}
        className={`name ${tagName === tag.name ? 'active' : ''}`}
      >
        <span
          onClick={() => setTagName(tag.name)}
          className="filter-item"
        >
          {tag.name}
        </span>
      </button>
    );
  };

  if (!posts.length && !isLoading) return <PageNotFound />;

  return (
    <>
      <div className="blog-main">
        <div className="site-content has-cover mb-10">
          <LoadingSpinner isLoading={isLoading} className="mt-5">
            <h1 className="blog_head mt-5">{tagName || 'All Posts'}</h1>

            <div className="categories-container svelte-1fjo4ay">
              <div className="filter-container  d-flex align-items-center justify-content-center mb-5">
                <button type="button" className={`name ${tagName === '' ? 'active' : ''}`}>
                  <span
                    onClick={() => setTagName('')}
                    className="filter-item "
                  >
                    All Posts
                  </span>
                </button>
                {_.map(tags, renderTagsList)}
              </div>
            </div>

            <main id="site-main" className="site-main outer">
              <div className="inner posts">

                <div className="post-feed" />
                <div className="pad-230">
                  <div className="row">
                    <div className="col-lg-12" />
                    {_.map(posts, renderPostCard)}
                  </div>
                </div>
              </div>
            </main>
          </LoadingSpinner>
        </div>

        <div className="pagination mb-5 d-flex justify-content-center align-items-center">
          { pagination.total > PER_PAGE_LIMIT && (
          <>
            <Pagination
              totalPages={pagination.pages}
              currentPageIndex={Number(pagination.page - 1)}
              onPageChange={({ selected: selectedPage }) => {
                setCurrentPageIndex(selectedPage);
              }}
            />
            <span className="page-number m-3">
              {' '}
              Page
              {' '}
              {pagination.page}
              {' '}
              of
              {' '}
              {pagination.pages}
            </span>
          </>
          )}
        </div>
      </div>
    </>
  );
};

export default withErrorBoundary(Blogs);
