export const LANGUAGES = [
  'Tamil',
  'Malayalam',
  'Telugu',
  'Kannada',
  'Hindi',
  'English',
  // 'Bengali',
  // 'Marathi',
  // 'Urdu',
  // 'Gujarati',
  // 'Odia',
  // 'Punjabi',
  // 'Assamese',
  // 'Rajasthani',
  // 'Haryanvi',
  // 'Kashmiri',
  // 'Sindhi',
  // 'Konkani',
  // 'Maithili',
  // 'Santali',
  // 'Nepali',
  // 'Bhojpuri',
  // 'Dogri'
];
