import React from 'react';
import PropTypes from 'prop-types';
import MoneyDisplay from 'common/components/MoneyDisplay';
import BgBlink from 'common/components/BgBlink/index';
import { isNumber } from 'lodash';
import TransactionType from 'common/components/TransactionType';
import ForceExitConfirmation from 'common/components/ForceExitConfirmation/index';
import { pairedPropTypes } from './pairedPropTypes';

const propTypes = {
  pairedOrder: pairedPropTypes,
  priceData: PropTypes.number,
  onForceExit: PropTypes.func.isRequired,
  isShowIndividualSquareOff: PropTypes.bool.isRequired
};

const defaultProps = { pairedOrder: {}, priceData: null };

const CardLeg = ({
  pairedOrder, priceData, onForceExit, isShowIndividualSquareOff
}) => {
  const {
    entryType, exitType, orderProfit, quantity, entryPrice, exitPrice,
    tradingSymbol, shouldCalculateUnrealizedProfit, isManualExitShow
  } = pairedOrder;
  const isUnRealizedProfitCalculation = shouldCalculateUnrealizedProfit && priceData;
  const percentageDiff = (initialValue, nextValue) => {
    return 100 * Math.abs((initialValue - nextValue) / initialValue);
    // return 100 * Math.abs((initialValue - nextValue) / ((initialValue + nextValue) / 2));
  };

  const currentExitPrice = isUnRealizedProfitCalculation ? priceData : exitPrice;
  const percentage = (currentExitPrice && entryPrice) ? percentageDiff(entryPrice, currentExitPrice) : null;

  const renderPrice = (price) => {
    if (!_.isNumber(price) || price === 0) return '-';

    return <MoneyDisplay shouldColor>{price}</MoneyDisplay>;
  };

  const renderExitSide = () => {
    if (isUnRealizedProfitCalculation) {
      return (
        <div className="exit-side text-right">
          {priceData ? (
            <div className="d-flex">
              <span className="text-muted">LTP : </span>
              <span className="ml-1"><BgBlink>{priceData}</BgBlink></span>
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <div className="exit-side text-right">
        <div className="text-muted">Exit</div>
        <TransactionType type={exitType} />
        <div className="">{renderPrice(exitPrice)}</div>
      </div>
    );
  };

  const renderPercentage = () => {
    if (!isNumber(percentage) || percentage === Infinity) return null;

    return (
      <div className="tx-10">
        {Math.abs(_.round(percentage, 2)) || percentage}
        %
      </div>
    );
  };

  return (
    <div className="card border-0">
      <div className="card-head d-flex">
        <div className="text-truncate">
          <div className="font-weight-semi trading-symbol">
            {tradingSymbol}
          </div>
          <div className="d-flex align-items-center tx-10">
            <div className="mr-2">
              <span className="text-muted">Qty : </span>
              <span className="">{quantity}</span>
            </div>
          </div>
        </div>

        <div className="tx-12 text-right text-nowrap ml-auto">
          {renderPercentage()}
          <div className="font-weight-medium">{renderPrice(orderProfit)}</div>
        </div>
      </div>

      <div className="card-body d-flex justify-content-between">
        <div className="enter-side">
          <div className="text-muted">Entry</div>
          <TransactionType type={entryType} />
          <div className="">{renderPrice(entryPrice)}</div>
        </div>
        {renderExitSide()}
      </div>
      <div className="d-flex justify-content-end border-top pt-1">
        {(isManualExitShow && isShowIndividualSquareOff)
          && <ForceExitConfirmation onForceExit={onForceExit} label="Close position" />}
      </div>
    </div>
  );
};

CardLeg.propTypes = propTypes;
CardLeg.defaultProps = defaultProps;

export default CardLeg;
