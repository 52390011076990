import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { onChangeOrderType } from 'v2/modules/withRunForm/events/orderType';
import { getSanitizedCases } from './cases';
import { getSanitizedPipes } from './pipes';
import { validateTrailingStopLoss } from './trailingStopLoss';
import { addOverallTransactionTSL } from './overallTransactionTSL';

const getSanitizedBounds = (bounds) => {
  const isOmitOverallStopLoss = !bounds?.stopLoss?.value;
  const isOmitOverallStopGain = !bounds?.stopGain?.value;
  const isOmitOverallDayStopLoss = !bounds?.dayStopLoss?.value;
  const isOmitOverallDayStopGain = !bounds?.dayStopGain?.value;

  return _.omit(bounds, [
    isOmitOverallStopLoss ? 'stopLoss' : null,
    isOmitOverallDayStopLoss ? 'dayStopLoss' : null,
    isOmitOverallDayStopGain ? 'dayStopGain' : null,
    isOmitOverallStopGain ? 'stopGain' : null,
  ]);
};

const getIntentMoreSanitized = (intent, pipeConfigs) => {
  const isBasketIntent = _.get(intent, 'uiConfigs.isBasketIntent', false);

  if (!isBasketIntent) {
    return intent;
  }

  const { name } = intent?.uiConfigs || {};
  const pipeConfig = _.find(pipeConfigs, { name });

  if (!pipeConfig) {
    return intent;
  }
  // When this ?
  // we already altered the pipeConfig, so we can just use it

  return {
    ...intent,
    instrument: {
      ...intent.instrument,
      when: pipeConfig?.config?.when,
    },
  };
};

const getCaseMoreSanitized = (kase, pipeConfigs) => {
  const {
    entry: { intents },
    adjustments,
  } = kase;

  return {
    ...kase,
    entry: {
      ...kase.entry,
      intents: _.map(intents, (intent) => getIntentMoreSanitized(intent, pipeConfigs)),
    },
    adjustments: _.map(adjustments, (adjustmentConfig) => ({
      ...adjustmentConfig,
      intents: _.map(adjustmentConfig.intents, (intent) => getIntentMoreSanitized(intent, pipeConfigs)),
    })),
  };
};

const validateCases = (sanitizedCases) => {
  const trailingStopLossError = validateTrailingStopLoss(sanitizedCases);
  if (trailingStopLossError) {
    const { name, error } = trailingStopLossError;
    reactToastify(
      `(${name}): ${error}`,
      TOASTIFY_TYPES.ERROR
    );
    throw new Error(error);
  }
};

export const getSanitizedQuantConfig = (quantConfig, runType, orderType) => {
  const {
    cases, uiBehaviourFlags, bounds, pipeConfigs, instrumentGroups
  } = quantConfig;

  const sanitizedCases = getSanitizedCases(
    cases,
    uiBehaviourFlags,
    pipeConfigs
  );

  validateCases(sanitizedCases);

  const sanitizedPipes = getSanitizedPipes(
    pipeConfigs,
    instrumentGroups,
    runType,
    sanitizedCases
  );

  let newQuantConfig = {
    ...quantConfig,
    cases: _.map(sanitizedCases, (kase) => getCaseMoreSanitized(kase, sanitizedPipes)),
    bounds: getSanitizedBounds(bounds),
    pipeConfigs: sanitizedPipes,
  };

  newQuantConfig = addOverallTransactionTSL(newQuantConfig);

  return onChangeOrderType({ orderType, quantConfig: newQuantConfig, runType })
    .quantConfig;
};
