export const BROKERS = _.get(window, 'brokerConfigs', {});
export const XTS_API_VENDOR = 'xtsApiVendor';
export const QUOTA_FOR_SECOND_WISE_BACKTEST = 4;

export const BFO_INSTRUMENTS = ['SENSEX', 'BANKEX'];

export const ORDER_TYPES = {
  paper: { value: 'paper', displayName: 'Paper Testing' },
  oneClick: { value: 'oneclick', displayName: 'Oneclick Trading' },
  automatic: { value: 'automatic', displayName: 'Auto Trading' }
};

export const RUN_TYPES = { live: 'live', historic: 'historic' };
export const RUN_GRANULARITY = { second: 'second', minute: 'minute' };

export const MAX_HISTORIC_COMBINED_RESULTS = 10;

export const ORDER_CONFIRMATION_STATUSES = { NEW: 'new', IGNORED: 'ignored', CONFIRMED: 'confirmed' };

export const NOTIFICATION_EVENTS = {
  RUN: {
    NAME: 'Run', EVENTS: { ERRORED: 'errored', },
  },
  ORDER: {
    NAME: 'Order',
    EVENTS: {
      ENTRY_ORDERS_FAILED: 'entry_orders_failed',
      STOPLOSS_ORDERS_FAILED: 'stoploss_orders_failed',
      EXIT_ORDERS_FAILED: 'exit_orders_failed',

      ENTRY_ORDERS_CANCEL_FAILED: 'entry_orders_cancel_failed',
      STOPLOSS_ORDERS_CANCEL_FAILED: 'stoploss_orders_cancel_failed',

      ENTRY_ORDERS_MODIFY_FAILED: 'entry_orders_modify_failed',
      EXIT_ORDERS_MODIFY_FAILED: 'exit_orders_cancel_failed',
    }
  },
  ORDERV2: {
    NAME: 'OrderV2'
  }
};

export const DOMAINS = {
  ANGEL_ONE: 'angelone',
  ALICE_BLUE: 'aliceblue',
  PAYTM_MONEY: 'paytmmoney',
  DEFAULT_DOMAIN: 'www',
  ICICI_DIRECT: 'icicidirect',
  GOODWILL: 'goodwill',
  BIGUL: 'bigul',
  DHAN: 'dhan',
  NUVAMA: 'nuvama',
  ENRICH_MONEY: 'enrichmoney',
  KOTAK_NEO: 'kotakneo',
  SHAREKHAN: 'sharekhan',
  RUPEEZY: 'rupeezy',
  PHILLIP_CAPITAL: 'phillipcapital',
  SAMCO: 'samco',
  UPSTOX: 'upstox',
  INVESTRIGHT: 'investright',
  HDFCSKY: 'hdfcsky',
  FIVEPAISA: '5paisa',
  GROWW: 'groww',
  ZERODHA: 'zdha',
  FLATTRADE: 'flattrade',
  FINVASIA: 'finvasia',
  FYERS: 'fyers',
  ZEBU: 'zebu',
  DHANISTOCKS: 'dhanistocks',
  IIFL: 'iifl',
};

export const REMOVE_BACKTEST_CONTENT_ON_BROKER_DOMAINS = ['sharekhan'];

export const REMOVE_INSTAOPTIONS_PROMO_ON_BROKER_DOMAINS = ['bigul', 'upstox'];

export const CALLBACK_SUPPORTED_ON_BROKER_DOMAINS = [
  'hdfcsky',
  'paytmmoney',
  'flattrade',
  'paytm',
  'sharekhan',
  'invest_right',
  'upstox'
];

export const QUANT_SCHEMA_CATEGORIES = {
  COMBINED_PREMIUM: 'combinedPremium',
  FUTURE_PRICE: 'future',
  EQUITY_PRICE: 'equity',
  INDICATOR: 'indicator',
  INDIAVIX: 'indiaVix',
  INDIVIDUALSTOP: 'individualStop',
  CUSTOM_PROP: 'custom',
  STRATEGY_STOP: 'strategyStop'
};

export const OPTION_TYPES = {
  CE: 'CE',
  PE: 'PE'
};

export const TRANSACTION_TYPES = {
  buy: 'buy',
  sell: 'sell'
};

export const SEGMENT_CONFIG = {
  future: 'future',
  equity: 'equity',
  option: 'option'
};

export const PRODUCT_TYPES = {
  mis: { label: 'MIS', value: 'mis' },
  normal: { label: 'Normal', value: 'normal' },
};

export const STOPS_VALUE_TYPES = {
  Percent: 'Percent',
  Amount: 'Amount',
  Points: 'Points',
};

export const INTENT_TYPES = {
  OpenLeg: 'OpenLeg',
  CloseLeg: 'CloseLeg',
  UpdateStopLoss: 'UpdateStopLoss',
  ReEntry: 'ReEntry',
};

export const WAIT_AND_TRADE_TYPES = {
  WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE: 'waitUntilPremiumToIncreaseInPercentage',
  WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE: 'waitUntilPremiumToDecreaseInPercentage',
};

export const SEGMENT_NAMES = [
  { value: SEGMENT_CONFIG.future, displayName: 'Future & Options' },
  { value: SEGMENT_CONFIG.equity, displayName: 'Equity & Index' },
  // { value: SEGMENT_CONFIG.equityAndFuture, displayName: 'Equity & F&O' }
];

export const SEGMENT_CATEGORY_IN_QUANT = {
  month1Price: 'month1Price',
  equity: 'equity'
};

export const FIXED_AMOUNT = 'fixed_amount';
export const FREE = 'free';
export const REQUEST_TYPE = 'request_type';

export const PURCHASE_TYPES = [
  { value: FREE, label: 'Free Strategy' },
  // { value: FIXED_AMOUNT, label: 'Paid Strategy' }
  // { value: REQUEST_TYPE, label: 'Request / Approval' },
];

export const highRiskHighReward = 'high_risk_high_reward';

export const RISK_CATRGORIES = [
  { value: 'low_risk_consistent_Reward', label: 'Low', imagePath: '/low-risk.png' },
  { value: 'medium_risk_medium_reward', label: 'Medium', imagePath: '/medium-risk.png' },
  { value: highRiskHighReward, label: 'High', imagePath: '/high-risk.png' }
];

export const STRATEGY_ACCESS_STATUS = {
  REQUESTED: 'requested',
  PAID: 'paid',
  REJECTED: 'rejected'
};

export const SEGMENT_SUPPORT_CONFIGS = (segments) => {
  let config = { isSupportEquity: false, isSupportFuture: false, isSupportOption: false };
  if (_.isEmpty(segments)) return config;

  if (_.includes(segments, SEGMENT_CONFIG.equity)) config = { ...config, isSupportEquity: true };
  if (_.includes(segments, SEGMENT_CONFIG.future)) config = { ...config, isSupportFuture: true };
  if (_.includes(segments, SEGMENT_CONFIG.option)) config = { ...config, isSupportOption: true };

  return config;
};

export const LIVE_ICON = 'icons-live';
export const BACK_TEST_ICON = 'icons-history';
export const MARKET_PLACE_ICON = 'material-icons-marketplace';

export const MARKET_START_TIME = '09:15';
export const MARKET_END_TIME = '15:30';

export const slippageTypes = {
  price: 'price',
  percentage: 'percentage'
};

export const TRADINGSYMBOL_REGEX = new RegExp(
  [
    '(?<symbol>[A-Z,&-]+)',
    '(?<date>[\\d]{2})',
    '(?<month_name>[A-Z]{3})',
    '(?<year>[\\d]{2})',
    '(?<strike_price>[\\d/.]+)?',
    '(?<option_type>CE|PE|FUT)',
  ].join(''),
);
