import React, { memo } from 'react';

const PageNotFound = () => {
  return (
    <>
      <div className="vh-100 d-flex flex-row align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <div className="col-10 col-sm-10 col-md-6 col-lg-6 m-auto">
                <img src="/error.png" alt="something went wrong" className="mw-100" />
              </div>
              <h1 className="display-4 d-block font-weight-bold">404. Page Not found.</h1>
              <div className="mb-4 lead text-muted">The Page you are looking for does not exist.</div>
              <a href="/" className="btn btn-outline-primary rounded-pill">Back to Home</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(PageNotFound);
