import React, { useState, useEffect, useMemo } from 'react';
import GhostContentAPI from '@tryghost/content-api';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import LoadingSpinner from 'common/components/LoadingSpinner';
import moment from 'moment';
import PageNotFound from './PageNotFound';
import PostCard from './PostCard';

const ghostsApi = new GhostContentAPI({
  host: _.get(window, 'SA_CONFIGS.ghostAPIHost', 'https://www.quantman.in/blog'),
  key: _.get(window, 'SA_CONFIGS.ghostAPIKey', ''),
  version: 'v5.0'
});

const propTypes = {
  propsSlugName: PropTypes.string.isRequired
};

const IndividualBlog = ({ propsSlugName }) => {
  const slugName = useMemo(() => propsSlugName, [propsSlugName]);
  const [blog, setBlog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [relatedPosts, setRelatedPosts] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const result = await ghostsApi.posts.read(
          { slug: slugName },
          { formats: ['html', 'plaintext'] }
        );
        const relatedBlogs = await ghostsApi.posts.browse({ limit: 4 });
        setBlog({ ...result, html: result.html.replace(/<\/?o:p>/g, '') });
        setRelatedPosts(_.filter(relatedBlogs, (item) => item.id !== result.id));
      } catch (e) {
        console.log('Error while fetching post', e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [slugName]);

  const renderPostCard = (post) => {
    return (
      <div key={post.id}>
        <PostCard post={post} />
      </div>
    );
  };

  if (!blog.html && !isLoading) {
    return <PageNotFound />;
  }

  return (
    <div className="view-individual-blogs post-template is-head-middle-logo has-cover is-dropdown-loaded ">

      <div className="viewport">

        <LoadingSpinner isLoading={isLoading} className="mt-5">
          <div className="container wrap mt-5">
            <header id="gh-head" className="gh-head outer">
              <a href="../blog">
                <button type="button" className="back-btn">
                  <i className="fa-solid fa-circle-arrow-left mr-12" />
                  Back
                </button>
              </a>
            </header>
            <main id="site-main" className="site-main">
              <article className={`article post ${blog.feature_image ? '' : 'no-image'}`}>

                <header className="">

                  <div className="article-tag post-card-tags" />

                  <h1 className="article-inner-title mb-2 mt-4">{blog.title}</h1>

                  <div className="article-byline">
                    <section className="article-byline-content d-flex align-items-center mb-3 mt-3">

                      <ul className="author-list m-0">
                        <li className="author-list-item">
                          <button
                            type="button"
                            className="author-avatar author-profile-image"
                            aria-label="Read more of Quantman"
                          >
                            <img src="/favicon.ico" alt="QUANTMAN" style={{ objectFit: 'contain' }} />
                          </button>
                        </li>
                      </ul>

                      <div className="article-byline-meta ml-12">
                        <h4 className="author-name">Quantman</h4>
                        <div className="byline-meta-content">
                          <time className="byline-meta-date">
                            {
                            moment(blog.created_at).format('DD MMM YYYY')
                          }
                          </time>
                          <span className="byline-reading-time">
                            <span className="bull">*</span>
                            {' '}
                            {`${blog.reading_time} min read`}
                          </span>
                        </div>
                      </div>

                    </section>
                  </div>

                  {blog.feature_image && (
                  <figure className="article-image mb-3">
                    <img
                      sizes="(min-width: 1400px) 1400px, 92vw"
                      src={blog.feature_image}
                      alt={blog.title}
                      className="mb-3"
                    />
                  </figure>
                  )}

                </header>

                <section className="">
                  {ReactHtmlParser(blog.html || '<span>404 Not Found</span>')}
                </section>

              </article>
            </main>
            <div className="related-posts mt-5">
              <div className="related-posts-header">
                <h2 className="mb-3 mt-3 font-weight-bold">Related Posts: </h2>
              </div>
              <div className="related-posts-list">
                {_.map(relatedPosts, renderPostCard)}
              </div>
            </div>

          </div>
        </LoadingSpinner>

      </div>

    </div>
  );
};

IndividualBlog.propTypes = propTypes;

export default IndividualBlog;
