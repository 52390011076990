import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { DOMAINS } from 'common/constants';
import { getSessionStorage, setSessionStorage } from 'common/utils/session';
import { initiateBrokerCallbackLogin } from 'common/auth/modalLogin';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import {
  brokerStructuredData,
  // loginPageTopBrokers
} from '../config';
import AngelOneLogin from '../specificDomainLoginPages/AngelOneLogin';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import AuthenticationModal from '../AuthenticationModal/index';
import AlicBlueLogin from '../specificDomainLoginPages/AlicBlueLogin';
import PaytmLogin from '../specificDomainLoginPages/PaytmLogin';
import DhanLogin from '../specificDomainLoginPages/DhanLogin';
import DhaniStocksLogin from '../specificDomainLoginPages/DhaniStocksLogin';
import GoodWillLogin from '../specificDomainLoginPages/GoodWillLogin';
import IciciDirectLogin from '../specificDomainLoginPages/IciciDirectLogin';
import NuvamaLogin from '../specificDomainLoginPages/NuvamaLogin';
import BigulLogin from '../specificDomainLoginPages/bigulLogin';
import IiflLogin from '../specificDomainLoginPages/IiflLogin';
import FlattradeLogin from '../specificDomainLoginPages/FlattradeLogin';
import FinvasiaLogin from '../specificDomainLoginPages/FinvasiaLogin';
import RupeezyLogin from '../specificDomainLoginPages/RupeezyLogin';
import EnrichMoneyLogin from '../specificDomainLoginPages/EnrichMoneyLogin';
import BrokerAccountLoginModal from '../BrokerAccountLoginModal';
import KotakNeoLogin from '../specificDomainLoginPages/KotakNeoLogin';
import SharekhanLogin from '../specificDomainLoginPages/SharekhanLogin';
import BrokerSelectionDropDown from '../BrokerSelectionDropDown';
import PhilipCapitalLogin from '../specificDomainLoginPages/PhilipCapitalLogin';
import SamcoLogin from '../specificDomainLoginPages/samcoLogin';
import UpstoxLogin from '../specificDomainLoginPages/UpstoxLogin';
import InvestRightLogin from '../specificDomainLoginPages/investRightLogin';
import HdfcSky from '../specificDomainLoginPages/hdfcSky';
import GrowwLogin from '../specificDomainLoginPages/GrowwLogin';
import FivepaisaLogin from '../specificDomainLoginPages/FivePaisaLogin';
import ZerodhaLogin from '../specificDomainLoginPages/ZerodhaLogin';
import ZebuLogin from '../specificDomainLoginPages/ZebuLogin';
import FyersLogin from '../specificDomainLoginPages/FyersLogin';

const propTypes = {
  mode: PropTypes.string,
  // TODO: is this the best way?? should we set it ApplicationDomain as global var for all
  // components to consume without sending as props.
  supportedBrokers: PropTypes.arrayOf(PropTypes.string),
  allowAlternateLoginMode: PropTypes.bool,
  allowGoodwillDealer: PropTypes.bool
};
const defaultProps = {
  mode: 'signIn',
  supportedBrokers: null,
  allowAlternateLoginMode: true,
  allowGoodwillDealer: false
};

const ENTRY_MODES = [
  { label: 'Login', value: 'signIn' },
  { label: 'Register', value: 'signUp' }
];

const ENTRY_COMPONENTS = { signIn: SignIn, signUp: SignUp };
const CURRENT_MODE = 'user_auth_current_mode';
// const MAX_BROKERS_TO_SHOW = 11;

const UserAuth = (props) => {
  const { supportedBrokers, allowAlternateLoginMode } = props;
  const defaultMode = getSessionStorage(CURRENT_MODE);
  const [currentEntryMode, setCurrentEntryMode] = useState(defaultMode || '');
  const CurrentEntryComponent = ENTRY_COMPONENTS[currentEntryMode];
  const [currentBroker, setCurrentBroker] = useState('');
  const oneIndentation = ' ';
  const [selectedBroker, setSelectedBroker] = useState('');
  const [showBrokersLoginModal, setShowBrokersLoginModal] = useState(false);

  const { applicationDomain } = window;
  const isPrimaryDomain = (_.isNull(_.get(applicationDomain, 'id', null)));
  const isEmailUser = _.get(window, 'isEmailUser', false);
  const isAllowEmailLoginPlanPurchase = window.location.href.includes('plan_purchase');
  const queryParams = new URLSearchParams(window.location.search);
  const planName = queryParams.get('plan') || 'Live';
  const isBacktestPlan = (planName).includes('Backtest');

  useEffect(() => {
    return () => { setSessionStorage(CURRENT_MODE, ''); };
  }, []);

  const onHandleCurrentEntryMode = (value) => {
    if (currentEntryMode === value) {
      setCurrentEntryMode('');
      setSessionStorage(CURRENT_MODE, '');
    } else {
      setCurrentEntryMode(value);
      setSessionStorage(CURRENT_MODE, value);
    }
  };

  const renderEntryTabItem = ({ label, value }) => {
    const buttonClassName = classNames('btn btn-block badge-pill btn-outline-primary', {
      'mt-0 active': (value === currentEntryMode)
    });

    return (
      <button
        type="button"
        key={value}
        className={buttonClassName}
        id={value}
        onClick={() => { onHandleCurrentEntryMode(value); }}
      >
        {label}
      </button>
    );
  };
  const currentComponentDivClassName = classNames('exp-login', { 'exp-login-open': (CurrentEntryComponent) });

  const renderAlternateLoginContent = () => {
    if (!allowAlternateLoginMode || isAllowEmailLoginPlanPurchase) {
      return null;
    }

    return (
      <>
        <div className="d-flex align-items-center mb-2 mt-4">
          <div className="border-bottom w-100" />
          <span className="px-2 text-muted font-weight-bold text-muted">OR</span>
          <div className="border-bottom w-100" />
        </div>

        <div className="text-center">
          Continue with
          {oneIndentation}
          <button
            type="button"
            className="btn btn-link font-weight-bold px-0"
            onClick={() => { onHandleCurrentEntryMode('signIn'); }}
          >
            <u>Email</u>
          </button>
        </div>
        {currentEntryMode && (
          <div className="d-flex align-items-center gap-20">
            {_.map(ENTRY_MODES, renderEntryTabItem)}
          </div>
        )}

        <div className={currentComponentDivClassName}>
          {currentEntryMode && <div className="expandable"><CurrentEntryComponent {...props} /></div>}
        </div>
      </>
    );
  };

  const sanitizedBrokerStructuredData = useMemo(() => _.filter(brokerStructuredData, (broker) => {
    return (
      (_.isEmpty(supportedBrokers) || _.includes(supportedBrokers, _.get(broker, 'brokerConfig.id', '')))
      && broker.isShow
    );
  }, [brokerStructuredData]));
  const selectedBrokerConfig = _.find(sanitizedBrokerStructuredData,
    (broker) => _.get(broker, 'brokerConfig.id', '') === selectedBroker);

  const onHandleAuthMode = () => {
    if (selectedBrokerConfig?.href) {
      if (!isPrimaryDomain) {
        window.location.href = selectedBrokerConfig.href;
      }

      initiateBrokerCallbackLogin(selectedBrokerConfig.href)
        .then((res) => {
          window.location.href = res?.href;
        })
        .catch((error) => {
          console.error(error);
          reactToastify(
            'Unauthorized, Please Login and continue',
            TOASTIFY_TYPES.ERROR
          );
        });
    } else setCurrentBroker(selectedBroker);
  };

  return (
    <>
      <div className="login-container">
        {isEmailUser && isAllowEmailLoginPlanPurchase && allowAlternateLoginMode && (
        <>
          <div className="d-flex justify-content-center alert alert-sm alert-info mb-3 mt-3">
            <b>
              Broker Login Required :
              {oneIndentation}
              {oneIndentation}
              {planName}
              {oneIndentation}
              run plan purchases are only allowed for Broker logins.
              {oneIndentation}
              {oneIndentation}
              Please log in with your Broker account to buy a plan.
            </b>
          </div>
            {isBacktestPlan && (
              <>
                <b className="d-flex justify-content-center">OR</b>
                <div className="d-flex justify-content-center alert alert-sm alert-info mb-3 mt-3">
                  <>
                    {oneIndentation}
                    <br />
                    <span>
                      <b>Backtest plan</b>
                      {oneIndentation}
                      can be purchased with an Email login.
                      To purchase Backtest plan with Email login,
                      <button
                        type="button"
                        onClick={() => {
                          window.allowBacktestPlanPurchase = true;
                          window.complete = true;
                        }}
                        className="btn-sm btn btn-success text-nowrap copy-btn track cursor-pointer m-2"
                      >
                        {' '}
                        {oneIndentation}
                        please click here
                      </button>
                    </span>
                  </>
                </div>
              </>
            )}
        </>
        )}
        <div className="login-panel-wrapper user-auth-component broker-new-login">
          <div className="login-panel">
            <div className="px-4 py-4">
              <h5 className="text-center mb-4 font-weight-semi">Login with your broker</h5>
              <div className="broker-login-container">
                {showBrokersLoginModal && (
                <BrokerAccountLoginModal
                  brokersConfig={sanitizedBrokerStructuredData}
                  onCloseModal={() => { setShowBrokersLoginModal(false); }}
                  onSetBroker={(brokerId) => {
                    setShowBrokersLoginModal(false);
                    setCurrentBroker(brokerId);
                  }}
                />
                )}
                <AuthenticationModal broker={currentBroker} onCloseModal={() => setCurrentBroker('')} />
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="broker-login-box">
                  <div className="text-center mb-3 mt-2">
                    Continue with
                    {oneIndentation}
                    <b>Broker</b>
                    {' '}
                    select here
                  </div>
                  <BrokerSelectionDropDown
                    selectedBroker={selectedBroker}
                    brokerStructuredData={sanitizedBrokerStructuredData}
                    onHandleSelectedBroker={setSelectedBroker}
                  />
                </div>

                <button
                  type="button"
                  className="btn btn-primary w-50"
                  onClick={onHandleAuthMode}
                >
                  Login with broker
                </button>
              </div>
              {renderAlternateLoginContent()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

UserAuth.propTypes = propTypes;
UserAuth.defaultProps = defaultProps;

export default (props) => {
  const { currentDomain } = window;
  switch (currentDomain) {
    case DOMAINS.ANGEL_ONE:
      return <AngelOneLogin />;
    case DOMAINS.ALICE_BLUE:
      return <AlicBlueLogin />;
    case DOMAINS.ICICI_DIRECT:
      return <IciciDirectLogin />;
    case DOMAINS.GOODWILL:
      return <GoodWillLogin {...props} />;
    case DOMAINS.PAYTM_MONEY:
      return <PaytmLogin />;
    case DOMAINS.BIGUL:
      return <BigulLogin />;
    case DOMAINS.FLATTRADE:
      return <FlattradeLogin />;
    case DOMAINS.FINVASIA:
      return <FinvasiaLogin />;
    case DOMAINS.DHAN:
      return <DhanLogin />;
    case DOMAINS.NUVAMA:
      return <NuvamaLogin />;
    case DOMAINS.RUPEEZY:
      return <RupeezyLogin />;
    case DOMAINS.ENRICH_MONEY:
      return <EnrichMoneyLogin />;
    case DOMAINS.KOTAK_NEO:
      return <KotakNeoLogin />;
    case DOMAINS.SHAREKHAN:
      return <SharekhanLogin />;
    case DOMAINS.PHILLIP_CAPITAL:
      return <PhilipCapitalLogin />;
    case DOMAINS.SAMCO:
      return <SamcoLogin />;
    case DOMAINS.UPSTOX:
      return <UpstoxLogin />;
    case DOMAINS.INVESTRIGHT:
      return <InvestRightLogin />;
    case DOMAINS.HDFCSKY:
      return <HdfcSky />;
    case DOMAINS.GROWW:
      return <GrowwLogin />;
    case DOMAINS.FIVEPAISA:
      return <FivepaisaLogin />;
    case DOMAINS.FYERS:
      return <FyersLogin />;
    case DOMAINS.ZEBU:
      return <ZebuLogin />;
    case DOMAINS.ZERODHA:
      return <ZerodhaLogin />;
    case DOMAINS.IIFL:
      return <IiflLogin />;
    case DOMAINS.DHANISTOCKS:
      return <DhaniStocksLogin />;
    default:
      return <UserAuth {...props} />;
  }
};
