import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { runPropTypes } from 'common/propTypes';
import InlineEdit from 'common/components/InlineEdit';
import { runStatusColorCode } from 'common/configs/runStatus';
import {
  BACK_TEST_ICON, LIVE_ICON, MARKET_PLACE_ICON, RUN_TYPES
} from 'common/constants/index';
import classNames from 'classnames';
import { runListOrderTypeLabel } from 'common/utils/RunListUtil';
import ActionBtns from '../ActionBtns';

const propTypes = {
  run: runPropTypes.isRequired,
  onDeleteRun: PropTypes.func.isRequired,
  onUpdateRun: PropTypes.func.isRequired,
  onSelectRuns: PropTypes.func.isRequired,
  isFilteredRuns: PropTypes.bool.isRequired

};
const defaultProps = {};
const I18N_SCOPE = { scope: 'folders.show' };

const RunGridViewBody = ({
  onDeleteRun, onUpdateRun, onSelectRuns, isFilteredRuns, run
}) => {
  const {
    id,
    name,
    run_type: runType,
    display_name: displayName,
    created_at: createdAt,
    status,
    start_date: startDate,
    end_date: endDate,
    folder_name: folderName,
    strategy_id: strategyId,
    is_individual_deployed_run: isIndividualDeployedRun
  } = run;
  // const textClassName = (profit >= 0) ? 'text-success' : 'text-danger';
  const isBacktest = runType === RUN_TYPES.historic;
  const { statusText, statusClassName } = runStatusColorCode(status);
  const [checked, setChecked] = useState(false);

  const handleRunSelect = (runId) => {
    onSelectRuns(runId, !checked);
    setChecked(!checked);
  };

  const getIcon = () => {
    if (!isIndividualDeployedRun) return MARKET_PLACE_ICON;
    if (isBacktest) return BACK_TEST_ICON;

    return LIVE_ICON;
  };

  const gridClassName = classNames('run-list-details', { 'marketplace-run': !isIndividualDeployedRun });

  return (
    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2" key={id}>
      <div className={gridClassName}>
        <a href={`/runs/${id}/?locale=${I18n.locale}`} className="run-details">
          <div className="qd-head border-bottom d-flex p-2 align-items-center">
            <i className={getIcon()} alt={runType} title={runType} />
            <div className="col-11 pr-2">
              <h6>
                <InlineEdit
                  text={name || displayName}
                  onUpdate={(newName) => onUpdateRun(run, 'name', newName)}
                  tooltip="Edit Run name"
                  isShowEditIcon={!strategyId}
                />
              </h6>
              <small>
                {isBacktest ? `${startDate} - ${endDate}`
                  : `Created At - ${moment(createdAt).format('YYYY-MM-DD hh:mm:ss A')}`}
              </small>
            </div>

            {!isFilteredRuns && (
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input cursor-pointer opacity-100"
                  onChange={() => { handleRunSelect(id); }}
                  onClick={(e) => e.stopPropagation()}
                  checked={checked}
                />
              </div>
            )}
          </div>

          <div className="qd-inner mb-auto">
            <div>
              <small>Order Type</small>
              <h6 className="d-flex">
                {runListOrderTypeLabel(run)}
              </h6>
            </div>

            <div className="run-status-details">
              <small>Status</small>
              <h6>
                {status && <span className={statusClassName} />}
                {statusText}
              </h6>
            </div>
            {/* <div>
              <h6 className={textClassName}>0.0</h6>
              <small>{I18n.t('drawdown', I18N_SCOPE)}</small>
            </div>

            <div>
              <h6 className={textClassName}>00.00</h6>
              <small>{I18n.t('win_loss_ratio', I18N_SCOPE)}</small>
            </div> */}
          </div>

          <div className="qd-footer justify-content-between border-top d-flex p-2">
            <div className="created-info tx-11">
              <span>{I18n.t('created_at', I18N_SCOPE)}</span>
              <div className="font-weight-semi">{moment(createdAt).fromNow()}</div>
            </div>
          </div>
        </a>
        <ActionBtns
          onUpdateRun={onUpdateRun}
          onDeleteRun={onDeleteRun}
          run={run}
          folderName={folderName}
        />
      </div>
    </div>
  );
};

RunGridViewBody.propTypes = propTypes;
RunGridViewBody.defaultProps = defaultProps;

export default RunGridViewBody;
