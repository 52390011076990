import React, { useMemo } from 'react';
import { RUN_TYPES } from 'v2/common/constants/index';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import { setRootValue } from 'v2/modules/withRunForm/redux/actions';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import classNames from 'classnames';
import HoverMessage from 'common/components/HoverMessage';
import { onChangeRunType } from '../../events/runType';

const RunType = () => {
  const dispatch = useDispatch();
  const isHistoricRunDisabled = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'isHistoricRunDisabled')
  );
  const isLiveRunDisabled = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'isLiveRunDisabled')
  );
  const quantConfig = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig'));

  const runType = useSelector((state) => getSelectorBasedStoreValue(state, 'runType'));
  const orderType = useSelector((state) => getSelectorBasedStoreValue(state, 'orderType'));
  const onUpdateRunType = (newRunType) => {
    dispatch(setRootValue(onChangeRunType({ quantConfig, runType: newRunType, orderType })));
  };
  const isHistoric = useMemo(() => runType === RUN_TYPES.historic, [runType]);

  const currentUserBroker = _.get(window, 'currentUserBroker', '');
  const isUserSignedIn = _.get(window, 'isUserSignedIn', false);
  const isEmailUser = _.get(window, 'isEmailUser', false);

  const isNonBrokerUser = isUserSignedIn && _.isEmpty(currentUserBroker);

  const disabledMessage = () => {
    if (isEmailUser) {
      return 'Live Run creations are not supported for email susers. '
      + 'Please sign-in with broker account to create live runs.';
    }
    if (isNonBrokerUser) {
      return 'Run creations are not supported for non-broker users. '
        + 'Please sign-in with broker account to create runs.';
    }
    if (isHistoricRunDisabled && isLiveRunDisabled) {
      return 'Historic and live run creation is temporarily disabled';
    }
    if (isHistoricRunDisabled) return 'Historic run creation is temporarily disabled';
    if (isLiveRunDisabled) return 'Live run creation is temporarily disabled';

    return null;
  };

  const historicBtnClassName = classNames('btn btn-sm', {
    'inactive-btn': !isHistoric,
    'active-btn': isHistoric,
  });

  const liveClassName = classNames('btn btn-sm', {
    'inactive-btn': isHistoric,
    'active-btn': !isHistoric,
  });

  const renderToggleBtn = (
    <ToggleButtonGroup
      bsPrefix="btn-group btn-group-sm btn-group-toggle"
      type="radio"
      name="group-selector"
      value={runType}
      onChange={onUpdateRunType}
    >
      <ToggleButton
        bsPrefix={historicBtnClassName}
        disabled={isHistoricRunDisabled}
        value={RUN_TYPES.historic}
      >
        {/* {_.startCase(RUN_TYPES.historic)} */}
        Backtest
      </ToggleButton>

      <ToggleButton
        bsPrefix={liveClassName}
        disabled={isLiveRunDisabled || isNonBrokerUser}
        value={RUN_TYPES.live}
      >
        {_.startCase(RUN_TYPES.live)}
      </ToggleButton>
    </ToggleButtonGroup>
  );

  return (
    <div className="run-type-header">
      <label className="label_style d-none">Quant Run Type</label>
      {
      (isHistoricRunDisabled || isLiveRunDisabled || isNonBrokerUser)
        ? <HoverMessage title="Run Type" message={disabledMessage()}>{renderToggleBtn}</HoverMessage>
        : renderToggleBtn
      }
    </div>
  );
};

export default RunType;
