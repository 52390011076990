import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

const propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string.isRequired,
    feature_image: PropTypes.string,
    feature_image_caption: PropTypes.string,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    reading_time: PropTypes.number.isRequired,
    excerpt: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number,
};

const defaultProps = {
  index: 0
};

const PostCard = ({ post, index }) => {
  return (
    <>
      <div className={index === 0 ? 'row align-items-center  mb-5' : ''}>
        {post.feature_image && (
          <a
            className={index === 0 ? 'post-card-image-link col-lg-4 ' : 'post-card-image-link'}
            href={`../blog/${post.slug}`}
          >

            <img
              className="post-card-image"
              src={post.feature_image}
              alt={ReactHtmlParser(post.feature_image_caption || post.title)}
              loading="lazy"
            />

          </a>
        )}

        <div className={index === 0 ? 'post-card-content col-lg-8 my-auto' : 'post-card-content'}>

          <a className="post-card-content-link" href={`../blog/${post.slug}`}>
            <header className="post-card-header">
              <div className="post-card-tags" />
              <h2 className="post-card-title">
                { post.title }
              </h2>
            </header>
            <div className="post-card-excerpt">
              { post.excerpt }
            </div>
          </a>

          <footer className="post-card-meta d-flex justify-content-between mt-5">
            <div>
              <time className="post-card-meta-date" dateTime={post.created_at}>
                { moment(post.created_at).format('DD MMM YYYY') }
              </time>
              <span className="post-card-meta-length">
                {' '}
                {`${post.reading_time} min read`}
              </span>
            </div>
            <a href={`../blog/${post.slug}`}>
              <button type="button" className="btn btn-primary">
                View More
              </button>
            </a>
          </footer>

        </div>

      </div>
    </>
  );
};

export default memo(PostCard);

PostCard.propTypes = propTypes;
PostCard.defaultProps = defaultProps;
