export const validateTrailingStopLoss = (cases) => {
  return _.chain(cases)
    .map((caseConfig) => {
      const {
        entry: { intents: entryIntents },
        adjustments,
      } = caseConfig;

      return _.concat(entryIntents, _.chain(adjustments).map('intents').flatten().value());
    })
    .flattenDeep()
    .some(({ stopLoss }) => {
      const { trailingValue, adjustValue } = stopLoss?.trail || {};
      return _.isNumber(trailingValue) && _.isNumber(adjustValue) && trailingValue < adjustValue;
    })
    .thru((hasError) => (hasError
      ? { name: 'TrailingStopLoss', error: 'X should be Greater than or Equal to Y in the TrailingStopLoss' }
      : null))
    .value();
};
