import React, { useState } from 'react';

import loginInModal from 'v2/modules/withRunForm/utils/loginInModal';
import AuthenticationModal from '../AuthenticationModal/index';

const propTypes = {};

const defaultProps = {};

const DhaniStocksLogin = () => {
  const oneIndentation = ' ';
  const [currentBroker, setCurrentBroker] = useState('');

  return (
    <div className="borker-login login-panel-wrapper">
      <div className="panel">
        <div className="bg-white p-5 rounded text-center">
          <h4>
            Welcome to
            {oneIndentation}
            <b>Quantman</b>
          </h4>
          <p className="my-3 tx-16">
            Explore, Analyse and Learn via backtests on
            {oneIndentation}
            <b>6+ years</b>
            {oneIndentation}
            of data.
            Deploy strategies on live market with order execution
            integrated with Indiabulls Securities.
          </p>

          <button
            type="button"
            className="btn btn-outline-primary broker"
            onClick={() => loginInModal('/auth/dhanistocks')}
          >
            <span>LOGIN WITH INDIABULLS SECURITIES</span>
          </button>

          <AuthenticationModal broker={currentBroker} onCloseModal={() => setCurrentBroker('')} />
        </div>
      </div>
    </div>
  );
};

DhaniStocksLogin.propTypes = propTypes;
DhaniStocksLogin.defaultProps = defaultProps;

export default DhaniStocksLogin;
